<template>
  <el-pagination
    background
    layout="prev, pager, next"
    v-model:currentPage="page"
    :total="total"
    :page-size="pageSize"
    :pager-count="5"
    @current-change="handlePageChange"
  />
</template>

<script>
export default {
  name: 'AppPagination',
  emits: ['page-change'],
  props: ['total', 'page-size', 'current-page'],
  data() {
    return {
      page: this.currentPage,
    };
  },
  methods: {
    handlePageChange(val) {
      this.$emit('page-change', val);
    },
  },
};
</script>
