<template>
  <footer class="container-fluid">
    <div class="row upper-section">
      <div class="col-12 col-lg-6">
        <h6>Batters</h6>
        <p>
          <router-link :to="{ name: 'About' }">About</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'News' }">News</router-link>
        </p>
        <p>
          <router-link :to="{ name: 'Library' }">Library</router-link>
        </p>
        <p class="mb-2">
          <a href="https://www.weloop.org/en/privacy-policy" target="_blank" aria-label="Privacy Policy">Privacy Policy</a>
        </p>
      </div>
      <div class="col-12 col-lg-6">
        <h6>WeLOOP</h6>
        <p>
          <a href="https://weloop.org/en/" target="_blank" aria-label="Read more about WeLOOP">About WeLOOP</a>
        </p>
        <p>
          <a href="mailto:info@weloop.org" target="_blank" aria-label="Contact WeLOOP">Contact us</a>
        </p>
        <p class="text-bold">Follow us</p>
        <div class="social-icons">
          <a
            class="bi bi-linkedin"
            href="https://www.linkedin.com/company/weloop"
            target="_blank"
            aria-label="WeLOOP's LinkedIn page"
          ></a>
          <a
            class="bi bi-twitter"
            href="https://twitter.com/weloop_"
            target="_blank"
            aria-label="WeLOOP's Twitter page"
          ></a>
          <a
            class="bi bi-youtube"
            href="https://www.youtube.com/channel/UC1dyvTC2eHhPtmyhVoSNMkQ"
            target="_blank"
            aria-label="WeLOOP's YouTube channel"
          ></a>
        </div>
      </div>
    </div>
    <div class="row lower-section">
      <p class="footer-small">
        <a href="https://weloop.org/en/" target="_blank" aria-label="Read more about WeLOOP">WeLOOP S.A.R.L.</a> - <a href="https://www.google.com/maps/place/254+Rue+du+Bourg,+59130+Lambersart/@50.652598,3.0289803,17z/data=!3m1!4b1!4m5!3m4!1s0x47c32a97a9144d11:0x1e54ced44d286ece!8m2!3d50.652598!4d3.031169" target="_blank">Rue du Bourg 254, 59130 Lambersart </a>- <a href="mailto:info@weloop.org" target="_blank">info@weloop.org</a> -
        <a href="tel:+33981857682" target="_blank" aria-label="WeLOOP's phone number">+33 9 81 85 76 82</a> - V.A.T. FR 90830184420
      </p>
      <p class="footer-small">
        &copy; Copyright {{ year }} |
        <a href="https://weloop.org/en/" target="_blank">WeLOOP</a> - All rights reserved
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {
  margin-top: 5rem;
  text-align: center;
  h6 {
    margin-bottom: $sp;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    margin-bottom: calc($sp / 2);
    transition: $trs-default;
    a {
      text-decoration: none;
      color: $dark;
      &:hover {
        color: $danger !important;
      }
    }
  }
  .text-bold {
    font-size: 0.9rem;
    margin: $sp 0;
  }
  .lower-section {
    padding: 1rem 0rem;
    .footer-small {
      font-size: 14px;
      margin-bottom: calc($sp / 2);
    }
  }
  .upper-section {
    padding: 1rem 0rem;
    background-color: $light-grey;
    .social-icons {
      a {
        font-size: 25px;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &.bi-twitter {
          color: #1da1f2 !important;
        }
        &.bi-youtube {
          color: #ff0000 !important;
        }
        &.bi-linkedin {
          color: #0a66c2 !important;
        }
      }
    }
  }
}
</style>
