<template>
  <div class="container-fluid slider-container" v-loading="isLoading">
    <div class="row">
      <div class="col-9 slider-header">
        <el-tag>TOP NEWS</el-tag>
      </div>
    </div>
    <div class="row">
      <swiper
        :style="{
          '--swiper-navigation-color': '#585757',
          '--swiper-pagination-color': '#585757',
        }"
        :slides-per-view="1"
        :keyboard="{
          enabled: true,
        }"
        :autoplay="{
          delay: 5000,
          pauseOnMouseEnter: true,
        }"
        :loop="true"
        :pagination="{ clickable: true }"
        :allowTouchMove="true"
      >
        <swiper-slide v-for="item in items" :key="item">
          <div class="row slider-row">
            <div class="col-9">
              <slide-item :item="item" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Database from '@/utils/services/Database';
import SlideItem from '@/components/items/Slide';

import SwiperCore, { Navigation, Pagination, Keyboard, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, Pagination, Keyboard, Autoplay]);

export default {
  name: 'HomeFeatured',
  components: {
    Swiper,
    SwiperSlide,
    SlideItem,
  },
  data() {
    return {
      items: [],
      isLoading: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('news', {
        limit: 3,
        offset: 0,
        is_featured: true,
      });

      this.items = res.items;
      this.isLoading = false;
    },
  },
};
</script>
