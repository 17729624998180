<template>
  <div class="row" :class="`header-${side}`">
    <div class="col-12" :class="[side === 'center' ? '' : 'col-lg-6']">
      <h2 class="mb-4">{{ title }}</h2>
      <hr class="hr-primary" />
    </div>
    <div class="col-12 col-lg-6 slot-col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    side: {
      type: String,
      default: 'center',
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
