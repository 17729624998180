<template></template>

<script>
import { ElMessageBox } from 'element-plus';

export default {
  name: 'CookieBanner',
  data() {
    return {
      showBanner: false,
      cookie: {
        consent: true,
        date: new Date().toString(),
      },
    };
  },
  created() {
    const cookie = this.$cookies.get('user_consent');

    if (!cookie) this.openBanner();
  },
  methods: {
    setAnalytics() {
      this.$cookies.set('user_consent', JSON.stringify(this.cookie), '30d');
      this.$matomo.rememberCookieConsentGiven();
    },
    openBanner() {
      return ElMessageBox({
        title: 'Cookie consent',
        message:
          'This website uses cookies to track traffic and monitor its performance. To learn more, please visit our privacy policy.',
        confirmButtonText: 'I understand',
        cancelButtonText: 'Decline',
        type: 'info',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        beforeClose: (action, _, done) => {
          if (action === 'confirm') {
            this.setAnalytics();
            done();
          } else {
            this.$cookies.set(
              'user_consent',
              JSON.stringify({ ...this.cookie, consent: false }),
              '30d'
            );
            done();
          }
        },
      });
    },
  },
};
</script>
