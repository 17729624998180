<template>
  <div class="container empty-container">
    <div class="row">
      <h3>Sorry...</h3>
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.empty-container {
  min-height: 40vh;
  .row {
    padding: 1rem;
    text-align: center;
    border: 1px solid $light-grey;
    box-shadow: $shadow-default;
    max-width: 60%;
    margin: 0 auto;
    img {
      width: 150px;
      margin: 0 auto;
      padding: 1rem 0rem;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@include bp-down (lg) {
  .empty-container {
    .row {
      max-width: 90%;
    }
  }
}
</style>
